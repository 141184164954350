<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box order_done-wrap fx-column">
                <div class="order_bg-wrap fx-fill fx-column" :style="'background-image: linear-gradient( to top, rgb(62, 74, 88) 5%, rgba(255, 255, 255, 0) 50% ), url(' + description.logo + '); '">
                    <div v-if="order.orderstatus == 'P' || order.orderstatus == 'S'" class="order_done_txt fx-center fx-row">
                        <van-icon :name="require('@/assets/images/order/success_icon.png')"></van-icon>
                        <span>付款成功</span>
                    </div>
                    <div class="order_done_name">{{order.moviename}}</div>
                </div>
                <div style="margin-top: 10px;">
                    <div>{{description['场次']}} {{order.edition}}</div>
                    <div>{{order.cinemaname}} {{description['影厅']}}</div>
                    <div>{{description['影票']}}</div>
                </div>
                <div>
                    <!-- 预计{{chupiaoTime}}分钟出票,若出票失败将为您退款-->
                    <div class="fc-primary">{{chupiaoTime}}</div>
                </div>
                <div class="fx-column fx-end">
                    <van-button :color="$config.style.primaryColor" round size="small" style="padding-left: 20px;padding-right: 20px;" @click="toOrderDetail">查看电影票</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tradeNo: this.$route.query.tradeNo,
            order: {},
            // poster: 'https://gw.alicdn.com/tfscom/i4/O1CN011bOR6f59dAtJcFc_!!6000000003455-0-alipicbeacon.jpg'
        }
    },
    computed: {
        description() {
            return Object.keys(this.order).length > 0 ? JSON.parse(this.order.description) : {};
        },
        chupiaoTime() {
            return this.$store.getters.baseData.chupiaoTime;
        }
    },
    methods: {
        toOrderDetail() {
            if (this.order.orderstatus == 'P' || this.order.orderstatus == 'S') {
                this.$goPage('movie-order-detail', {
                    tradeNo: this.tradeNo
                }, true);
            } else {
                this.$toast('您的订单未支付');
            }
        },
        getOrderDetail() {
            this.$service.getOrderDetail({ tradeno: this.tradeNo }).then((res) => {
                this.order = res;
                if (!(this.order.orderstatus == 'P' || this.order.orderstatus == 'S')) {
                    this.$toast('订单结果查询未支付');
                    setTimeout(() => {
                        this.$goPage('movie-order', null, true);
                    }, 2800);
                }
            })
        },
        initPage() {
            this.getOrderDetail();
        }
    },
    mounted() {
        this.initPage();
    }
}
</script>

<style lang="scss" scpoed>
.body-wrap {
	position: relative;
	.order_done-wrap {
		background: #3f4956;
		padding: 12px;
		color: #fff;
		> div {
			line-height: 2;
		}
		.order_bg-wrap {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: 5px;
			justify-content: flex-end;
			.order_done_txt {
				> * {
					padding-right: 5px;
				}
				font-size: 18px;
			}
			.order_done_name {
				font-size: 15px;
			}
		}
	}
}
</style>
